@import "./variables.scss";
@import "features.scss";
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

body {
    font-family: 'Montserrat', sans-serif;
}


@media (min-width: 1000px) {
  .appTemplate .footerContainer {
    background: #006BB6;
  }

  .appTemplate .container {
    margin: 0 auto;
    padding-bottom: 30px;
  }

  .sectionTitle {
    margin: 0 auto;
    padding-left: 25px;
    color: #2A557F;
    font-size: 40px;
    font-weight: bold;
    text-align: left;
    padding: 15px;
    font-style: normal;
    font-family: 'Montserrat';
}


  /* Header Section */

  .headerBar img {
    width: 40%;
    height: auto;
    padding-top: 5px;
  }

  .headerBar .button {
    margin-top: 20px;
    font-size: 14px;
    float: right;
  }

  /* Footer Section */
  .footer {
    background: #006BB6;
    height: 100px;
    // clip-path: ellipse(125% 100% at 98.45% 0%);
  }

  .footer img {
    margin-top: 25px;
    float: left;
    width: 105px;
    padding-left: 10px;
  }

  .siteLogo {
    color: $white;
    margin-top: -27px;
    font-size: 18px;
    padding-left: 10px;
  }

  .appWrapper p {
    font-size: 20px;
    margin: 0 auto;
    width: 90%;
    line-height: normal;
    font-family: sans-serif;
  }

  .appWrapper h3 {
    font-size: 36px;
  }

  .MuiButton-root {
    background: #28A745;
    color: $white;
    font-size: 12px;
    width: 200px;
    text-align: center;
    display: block;
    margin: 0 auto;
    margin-bottom: 0;
    overflow: hidden;
    position: relative;
    // transform: translatez(0);
  }

  .mobileNavMenu {
    display: none;
  }

  .mobileNav .logo {
    width: 100px;
    margin: 0 auto;
    text-align: center;
    padding-bottom: 10px;
  }

  /* Navigation Bar */

  .navBar {
    margin: 10px auto;
    font-size: 20px;
    text-align: center;
    font-family: sans-serif;
    font-weight: 400;
  }

  .navBar a {
    text-decoration: none;
    color: $primary;
  }
  .navBar a:link {
    text-decoration: none;
    color: $white;
  }

  .navBar a:hover {
    color: #ffffff;
  }

  .mobileNavCloseMenu {
    background-color: #006BB6;
  }

  /* Nav Menu */
  #nav {
    list-style: none inside;
    margin: 0;
    padding: 0;
    text-align: center;
    width: 100%;
  }

  #nav ul {
    border: 2px solid $primary2;
    border-radius: 10px;
  }

  #nav li {
    display: block;
    position: relative;
    float: left;
    color: $primary2;
    border-radius: 15px;
  }

  #nav li a {
    display: block;
    padding: 0;
    text-decoration: none;
    width: 125px;
    line-height: 35px;
    color: $white;
    padding: 5px;
  }

  #nav li li a {
    font-size: 75%;
    color: $primary2;
    background: transparent;
  }

  #nav li:hover {
    color: $secondary;
  }

  #nav li li a:hover {
    font-size: 80%;
    color: $primary2;
    background: $secondary;
    border-radius: 5px;
  }

  #nav ul {
    position: absolute;
    padding: 0;
    left: 0;
    display: none;
  }

  #nav li:hover ul ul {
    display: none;
  }

  #nav li:hover ul {
    display: block;
  }

  #nav li li:hover ul {
    display: block;
    margin-left: 125px;
    margin-top: -35px;
  }

  .menuHamIcon {
    margin-top: -10px;
    text-align: center;
  }

  /* Page Content */

  /* Contact Us Container */
  .contactPageContainer {
    height: 100%;
  }
  .contactUsContentContainer {
   height: 100%;
  }

  .contactUsContentContainer h4 {
    color: $primary;
    font-size: 28px;

  }

  /* About AI Container */
  .aboutAIContainer {
    // background-image: $aboutAiBanner;
    background-position: $aboutAiBannerPosition;
    background-repeat: $aboutAiBannerRepeat;
    background-size: $aboutAiBannerSize;
    width: $aboutAiWidth;
    height: $aboutAiHeight;
    padding-bottom: $aboutAiPaddingBottom;
  }

  .aboutAIContainer img {
    margin-top: -10px;
  }

  .aboutAIContainer .aboutTitle {
    color: $aboutAiTitleColor;
    margin-top: $aboutAiTitleMarginTop;
    font-size: $aboutAiTitleFontSize;
    position: $aboutAiTitlePosition;
    padding-left: $aboutAiTitlePaddingLeft;
    margin-left: 75px;
    
  }

  .aboutAIContainer .aboutTitle .line {
    background: $aboutAiLineBackground;
    color: $aboutAiLineColor;
    display: $aboutAiLineDisplay;
    padding: $aboutAiLinePadding;
    text-decoration: $aboutAiLineTextDecoration;
    transition: $aboutAiLineTransition;
  }

  .aboutAIContainer .banner:hover {
    color: $aboutAiBannerHoverColor;
    --underline-width: 100%;
  }

  /* Apporach AI Container */

  .approachAIContainer {
    background-image: $approachAIContainerBckgrdImg;
    background-position: $approachAIContainerBckPosition;
    background-repeat: $approachAIContainerBckRepeat;
    background-size: $approachAIContainerBckgrdSize;
    height: $approachAIContainerHeight;
    padding: $approachAIContainerPadding;
    word-wrap: $approachAIContainerWordWrap;
    background: linear-gradient(-45deg, #94bad8, #cee7f9, #e4f3ff, #fdfeff);
    background-size: 400% 400%;
    animation: gradient 10s ease infinite;
  }

  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }

  .approachAITitle {
    margin-top: $approachAIContainerTitleMarginTop;
  }

  .approachAIContainer h3 {
    text-align: $approachAIContainerTitleH3Align;
    padding-left: $approachAIContainerTitleH3PaddingLeft;
    font-size: $approachAIContainerTitleH3FontSize;
    color: $approachAIContainerTitleH3Color;
  }

  /* Video Carosuel Home Section */
  .videoDemoCarouselContainer {
    height: $videoDemoCarouselContainerHeight;
    width: $videoDemoCarouselContainerWidth;
    padding-top: $videoDemoCarouselContainerPaddingTop;
    padding-bottom: $videoDemoCarouselContainerPaddingBottom;
    margin-top: $videoDemoCarouselContainerMarginTop;
  }

  .videoDemoCarouselContainer .sectionTitle {
    text-align: $videoDemoCarouselSctTleAlign;
  }

  .videoDemoCarouselContainer .sectionDescription {
    font-size: $videoDemoCarouselSctDescFontSize;
    margin: $videoDemoCarouselSctDescMargin;
    text-align: $videoDemoCarouselSctDescAlign;
  }

  .videoDemoCarouselContainer .sectionDescription .title {
    padding-top: $videoDemoCarouselSctDescPaddingTop;
  }

  .videoDemoCarouselContainer .sectionDescription .text {
    margin: $videoDemoCarouselSctDescTxtMargin;
    text-align: $videoDemoCarouselSctDescTxtAlign;
    font-size: $videoDemoCarouselSctDescTxtFontSize;
    width: $videoDemoCarouselSctDescTxtWidth;
  }

  .videoDemoCarouselContainer video {
    width: $videoDemoCarouselSctDescTxtWidth;
    height: $videoDemoCarouselSctDescTxtHeight;
    box-shadow: $videoDemoCarouselSctDescTxtBoxShadow;
    border-radius: $videoDemoCarouselSctDescTxtBrdRadius;
  }

  .videoDemoCarouselContainer h5 {
    color: $primary;
    font-size: $videoDemoCarouselSctDescH5FontSize;
  }

  .videoDemoCarouselContainer .button {
    margin-top: $videoDemoCarouselMarginTop;
    border-radius: $videoDemoCarouselBorderRadius;
    height: $videoDemoCarouselHeight;
    width: $videoDemoCarouselWidth;
    padding: $videoDemoCarouselPadding;
    background-color: $videoDemoCarouselBckgrdColor;
    color: $videoDemoCarouselColor;
    border: $videoDemoCarouselBorder;
    font-size: $videoDemoCarouselFontSize;
  }

  /* FAQ Page Section */

  .faqContentContainer {
    flex-grow: $faqContentContainerFlex;
    width: $faqContentContainerWidth;
    height: $faqContentContainerHeight;
    margin: $faqContentContainerMargin;
  }

  .faqContentContainer .accordionContainer {
    background-image: $faqContentAccContainerBckgrdImg;
    padding: $faqContentAccContainerPadding;
    border-radius: $faqContentAccContainerBrdRadius;
  }

  .faqContentContainer .category {
    font-size: $faqContentAccContainerCategoryFontSize;
    color: $faqContentAccContainerCategoryColor;
    text-align: $faqContentAccContainerCategoryAlign;
    padding: $faqContentAccContainerCategoryPadding;
  }

  .faqContentContainer .titleLine {
    width: $faqContentTitleLineWidth;
    height: $faqContentTitleLineHeight;
    background-image: $faqContentTitleLineBckgrdImg;
  }

  .faqContentContainer h5 {
    font-size: $faqContentH5FontSize;
    color: $faqContentH5Color;
  }

  .faqContentContainer p {
    margin: $faqContentPMargin;
    font-size: $faqContentPFontSize;
    width: $faqContentPWidth;
    color: $faqContentPColor;
    text-align: $faqContentPAlign;
  }

  /* partnersContainer */ 
  .partnersContainer {
    margin-top:  auto;
  }

  .partnersContainer h5 {
    color: $gunmetal;
    text-align: left;
    font-size: 26px;
  }

   /* PartnersCard */ 
   .PartnersCard {
    flex-direction: column;
    justify-content: space-between;
    text-align: left;
    height: 100%;
    min-height: 650px; 
    padding: 16px;
    box-sizing: border-box;
    background: #F8F8F8;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
  }


 .PartnersCard img{
  padding: auto;
  width: 200px; 
  height: auto;
  margin: 0 auto;
  text-align: center;
  border-radius: 4px;
}

  /* About Us Section */
  :root {
    --shadow-color: 0deg 0% 52%;
    --shadow-elevation-low:
      0.3px 0.5px 0.8px hsl(var(--shadow-color) / 0.11),
      0.5px 0.9px 1.4px -0.4px hsl(var(--shadow-color) / 0.27),
      1px 2px 3.1px -0.8px hsl(var(--shadow-color) / 0.42);
    --shadow-elevation-medium:
      0.3px 0.5px 0.8px hsl(var(--shadow-color) / 0.12),
      1.1px 2.2px 3.4px -0.3px hsl(var(--shadow-color) / 0.24),
      2.3px 4.6px 7.1px -0.5px hsl(var(--shadow-color) / 0.36),
      5px 10px 15.4px -0.8px hsl(var(--shadow-color) / 0.48);
    --shadow-elevation-high:
      0.3px 0.5px 0.8px hsl(var(--shadow-color) / 0.11),
      2.2px 4.5px 6.9px -0.1px hsl(var(--shadow-color) / 0.16),
      4px 8px 12.3px -0.2px hsl(var(--shadow-color) / 0.22),
      6px 12px 18.5px -0.3px hsl(var(--shadow-color) / 0.28),
      8.7px 17.4px 26.8px -0.5px hsl(var(--shadow-color) / 0.34),
      12.5px 24.9px 38.4px -0.6px hsl(var(--shadow-color) / 0.39),
      17.7px 35.5px 54.7px -0.7px hsl(var(--shadow-color) / 0.45),
      25px 50px 77.1px -0.8px hsl(var(--shadow-color) / 0.51);
  }

  .aboutUsPageContainer {
    flex-grow: $aboutUsPageContainerFlex;
    width: $aboutUsPageContainerWidth;
    height: $aboutUsPageContainerHeight;
    margin: $aboutUsPageContainerMargin;


  }

  .aboutUsPageContainer .pageTitle {
    color: $aboutUsPageTitleColor;
    font-weight: $aboutUsPageTitleFontWeight;
    font-size: $aboutUsPageTitleFontSize;
    
  }

  .aboutUsPageContainer .aboutUsInfoSection {
    margin: $aboutUsPageInfoSectionMargin;
    
  }

  .aboutUsPageContainer .aboutUsInfoSection .title {
    font-size: $aboutUsPageInfoSectionTitleFontSize;
    background-image: $aboutUsPageInfoSectionTitleBckgrdImg;
    color: $aboutUsPageInfoSectionTitleColor;
    padding: $aboutUsPageInfoSectionTitlePadding;
    
  }

  .aboutUsPageContainer .aboutUsInfoSection .description {
    font-size: $aboutUsPageInfoSectionDescFontSize;
    color: $aboutUsPageInfoSectionDescColor;
    padding: $aboutUsPageInfoSectionDescPadding;
    height: $aboutUsPageInfoSectionDescHeight;
    box-shadow: var(--shadow-elevation-low);
  }

  .aboutUsPageContainer .profileContainer {
    margin: $aboutUsProfileContainerMargin;
    
    
    
  }

  .profileContainer .profileInfo {
    margin: $aboutUsProfileContainerProfileInfoMargin;
  }

  .profileContainer .profileInfo .name {
    font-size: $aboutUsProfileInfoNameFontSize;
    color: $aboutUsProfileInfoNameColor;
    font-weight: $aboutUsProfileInfoNameFontWeight;
  }

  .profileContainer .profileInfo .title {
    font-size: $aboutUsProfileInfoTitleFontSize;
    color: $aboutUsProfileInfoTitleColor;
  }

  .profileContainer .profileInfo .email {
    font-size: $aboutUsProfileInfoEmailFontSize;
    color: $aboutUsProfileInfoEmailColor;
  }

  /* Demos Videos Page Section */
  .demoContainer .videoThumbnailContainer {
    height: $demoContainerHeight;
    background: $demoContainerBckgrdColor;
    border-radius: $demoContainerBorderRadius;
    overflow: $demoContainerOverflow;
    text-align: $demoContainerAlign;
  }

  .demoContainer .videoThumbnailContainer .Link:hover {
    color: $demoContainerThbnailLnkHover;
    background-image: linear-gradient(to bottom, #dbebf6, #f3f7f9);
  }

  .demoContainer .videoThumbnailContainer .listMediaTitle:hover {
    color: $demoContainerMediaTitleHoverColor;
  }

  .demoContainer .contentContainer {
    background-image: $demoContainerContentBckgrdImg;
    height: $demoContainerContentHeight;
  }

  .demoContainer h6 {
    font-size: $demoContainerH6FontSize;
  }

  .demoContainer small {
    color: $demoContainerSmallColor;
    opacity: $demoContainerSmallOpacity;
    font-size: $demoContainerSmallFontSize;
    text-align: $demoContainerSmallAlign;
  }

  /* Offerings Page */
  .offeringsContentContainer {
    height: 100%;
    padding-bottom: 100px;
  }

  .offeringsContentContainer a {
    text-decoration: none;
    color: $primary;
    font-size: 24px;
  }

  .offeringsContentContainer a:link {
    text-decoration: none;
    color: $primary;
    font-size: 24px;
  }

  .offeringsContentContainer a:hover {
    text-decoration: none;
    color: #74add5;
    font-size: 24px;
  }

  .offeringsContentContainer .container {
    margin: 0 auto;
    padding: 0 50px 0 50px;
  }

  .offeringsContentContainer .container .image {
    width: 350px;
    text-align: left;
  }

  .accelImg {
    width: 100%;
  }

  .offeringsContentContainer .title {
    text-align: left;
    margin: 0 auto;
    width: 75%;
  }

  .offeringsContentContainer .desc {
    text-align: left;
    width: 100%;
    margin: 0 auto;
  }

  /* Layer Cake */

  .layerCakeContent {
    border-radius: $layerCakeContentborderRadius;
    width: 97%;
    font-size: $layerCakeContentFontSize;
    margin: 25px auto;
    font-family: sans-serif;
    height: 100%;
  }

  .layerCakeContentSideA {
    font-size: 18px;
    padding-bottom: 50px;
  }

  .layerCakeContentSideB {
    // border-radius: 0 10px 10px 0;
    background-image: radial-gradient(#f2f2f2 1.5px, transparent 0.5px);
    background-size: 10px 10px;
    background-color: #ffffff;
    padding-bottom: 50px;
  }

  .layerCakeLayerTitle {
    margin-top: 25px;
    padding-left: 50px;
    text-align: left;
    font-size: 25px;
    color: $white;
  }

  .layerCakeContentSideA .desc {
    width: 80%;
    margin: 0 auto;
    font-size: 18px;
    text-align: left;
    color: $white;
  }

  .layerCakeContentSideB .header {
    text-align: left;
    font-weight: 600;
    width: 90%;
    margin: 25px auto;
  }

  .layerLogo {
    width: 200px;
  }

  /* Components */

  /* Container to center cards */
  .Card {
    display: flex;
    flex-direction: column;
    align-items: stretch; /* Ensure all cards stretch to fill the container */
    background: #f8f8f8; /* Light background for the card */
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    overflow: hidden; /* Ensures rounded corners are applied to the inner content */
    width: 100%; /* Full width of the container */
    height: 100%; /* Full height of the card container */
    max-width: 300px; /* Max width to keep it manageable */
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Distribute space between title and text */
    transition: transform 0.3s, box-shadow 0.3s; /* Smooth hover effect */
  }

  .Card:hover {
    transform: translateY(-5px); /* Slight lift on hover */
    background-color: #ffffff;
    // color: #F3BAB1;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* More pronounced shadow on hover */
  }
  
  .cardTitleContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%; /* Full width of the card */
    height: 100px; /* Fixed height for the title container */
    padding: 15px; /* Padding around title container */
  }
  
  .cardTitle {
    font-size: 18px; /* Font size for title */
    font-weight: 600;
    color: #f8f8f8; /* White text color */
    margin: 0; /* Remove default margin */
    text-align: center; /* Center-align text */
  }
  
  .cardBodyContainer {
    flex: 1; /* Takes up remaining space in the card */
    display: flex;
    align-items: center; /* Center content vertically */
    justify-content: center; /* Center content horizontally */
    padding: 10px 20px; /* Padding inside the body */
    box-sizing: border-box; /* Include padding in height */
  }
  
  .cardText {
    font-size: 16px; /* Font size for card text */
    color: #162227; /* Dark text color */
    word-wrap: break-word; /* Handle long words */
    line-height: 1.5; /* Line height for readability */
    text-align: center; /* Center-align text */
  }
  

  

  

  :root {
    --shadow-color: 0deg 0% 52%;
    --shadow-elevation-low:
      0.3px 0.5px 0.8px hsl(var(--shadow-color) / 0.11),
      0.5px 0.9px 1.4px -0.4px hsl(var(--shadow-color) / 0.27),
      1px 2px 3.1px -0.8px hsl(var(--shadow-color) / 0.42);
    --shadow-elevation-medium:
      0.3px 0.5px 0.8px hsl(var(--shadow-color) / 0.12),
      1.1px 2.2px 3.4px -0.3px hsl(var(--shadow-color) / 0.24),
      2.3px 4.6px 7.1px -0.5px hsl(var(--shadow-color) / 0.36),
      5px 10px 15.4px -0.8px hsl(var(--shadow-color) / 0.48);
    --shadow-elevation-high:
      0.3px 0.5px 0.8px hsl(var(--shadow-color) / 0.11),
      2.2px 4.5px 6.9px -0.1px hsl(var(--shadow-color) / 0.16),
      4px 8px 12.3px -0.2px hsl(var(--shadow-color) / 0.22),
      6px 12px 18.5px -0.3px hsl(var(--shadow-color) / 0.28),
      8.7px 17.4px 26.8px -0.5px hsl(var(--shadow-color) / 0.34),
      12.5px 24.9px 38.4px -0.6px hsl(var(--shadow-color) / 0.39),
      17.7px 35.5px 54.7px -0.7px hsl(var(--shadow-color) / 0.45),
      25px 50px 77.1px -0.8px hsl(var(--shadow-color) / 0.51);
  }

  /* Profile Card Section */

  :root {
    --shadow-color: 0deg 0% 52%;
    --shadow-elevation-low:
      0.3px 0.5px 0.8px hsl(var(--shadow-color) / 0.11),
      0.5px 0.9px 1.4px -0.4px hsl(var(--shadow-color) / 0.27),
      1px 2px 3.1px -0.8px hsl(var(--shadow-color) / 0.42);
    --shadow-elevation-medium:
      0.3px 0.5px 0.8px hsl(var(--shadow-color) / 0.12),
      1.1px 2.2px 3.4px -0.3px hsl(var(--shadow-color) / 0.24),
      2.3px 4.6px 7.1px -0.5px hsl(var(--shadow-color) / 0.36),
      5px 10px 15.4px -0.8px hsl(var(--shadow-color) / 0.48);
    --shadow-elevation-high:
      0.3px 0.5px 0.8px hsl(var(--shadow-color) / 0.11),
      2.2px 4.5px 6.9px -0.1px hsl(var(--shadow-color) / 0.16),
      4px 8px 12.3px -0.2px hsl(var(--shadow-color) / 0.22),
      6px 12px 18.5px -0.3px hsl(var(--shadow-color) / 0.28),
      8.7px 17.4px 26.8px -0.5px hsl(var(--shadow-color) / 0.34),
      12.5px 24.9px 38.4px -0.6px hsl(var(--shadow-color) / 0.39),
      17.7px 35.5px 54.7px -0.7px hsl(var(--shadow-color) / 0.45),
      25px 50px 77.1px -0.8px hsl(var(--shadow-color) / 0.51);
  }

  .ProfileCard {
    // height: 100%;
    padding: 16px; /* Adjust padding as needed */
    box-sizing: border-box;
    // background: #fff; /* Add any additional styles */
    // box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px; 
  }

  .ProfileCard img {
    text-align: center;
    margin: 25px auto;
    height: 200px;
    border-radius: 10px;
    box-shadow: var(--shadow-elevation-medium);
    // transform: rotate(2deg);
  }




  .badgeComponentContainer {
    float: left;
    margin: 0;
    padding: 20px;
  }

  #gear1,
  #gear2 {
    color: #888;
    display: block;
    float: left;
    position: absolute;
    margin: 50px 0 0 0;
  }

  #gear1 {
    top: 25px;
    left: 50px;
  }

  #gear2 {
    left: 190px;
  
  }

  .spin {
    -webkit-animation: spin 7s linear infinite;
    -moz-animation: spin 7s linear infinite;
    animation: spin 7s linear infinite;
  }

  .spin-back {
    -webkit-animation: spin-back 7s linear infinite;
    -moz-animation: spin-back 7s linear infinite;
    animation: spin-back 7s linear infinite;
  }

  @-moz-keyframes spin {
    100% {
      -moz-transform: rotate(360deg);
    }
  }
  @-webkit-keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  @keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  @-moz-keyframes spin-back {
    100% {
      -moz-transform: rotate(-360deg);
    }
  }
  @-webkit-keyframes spin-back {
    100% {
      -webkit-transform: rotate(-360deg);
    }
  }
  @keyframes spin-back {
    100% {
      -webkit-transform: rotate(-360deg);
      transform: rotate(-360deg);
    }
  }

/* koverseCatalogContainer */
.koverseCatalogContainer {
  height: 1000px;
}


}
