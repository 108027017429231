@import "./variables.scss";

@media (max-width: 1350px) {
  .desktopNav {
    display: none;
  }

  .mobileNavMenu {
    display: block;
  }
  .mobileNav {
    text-align: center;
    background: #006BB6;
  }

  .mobileNav .logo {
    width: 100px;
  }

  .mobileNavMenu {
    display: block;
  }

  .mobileNavLinks .MuiListItemText-root {
    text-align: center;
    color: #ffffff;

  }

  .mobileNavLinks a {
    text-decoration: none;
    color: $white;
    font-size: 16px;
  }
  .mobileNavLinks a:link {
    text-decoration: none;
    color: $white;
    font-size: 16px;
  }

  .mobileNavLinks a:hover {
    color: #00ACD4;
  }

  .menuHamIcon {
    margin-top: -10px;
    text-align: center;
  }

  .aboutAIContainer .aboutTitle {
    color: $primary;
    margin: 190px auto;
    font-size: 36px;
    text-align: center;
    background: transparent;
    padding-left: 60px;
    border-radius: 5px;
  }
 #gear1,
  #gear2 {
    color: #888;
    display: block;
    float: left;
    position: absolute;
    margin: 30px 0 0 0;
  }

}

@media (max-width: 1000px) {
  .sectionTitle {
    margin: 0 auto;
    color: $h1;
    font-size: 24px;
    text-align: center;
    padding: 15px;
    font-style: normal;
    font-family: sans-serif;
  }

  /* Header Bar Section */
  .headerBar img {
    width: 60%;
    height: auto;
    padding-bottom: 5px;
  }

  .headerBar .button {
    width: 130px;
    font-size: 14px;
    height: auto;
    padding-top: 5px;
    float: right;
    background-color: #28A745;
  }

  /* Footer Section */
  .footer {
    background: #f9f9f9;
    height: 100px;
    clip-path: ellipse(125% 100% at 98.45% 0%);
  }

  .footer img {
    margin-top: 25px;
    float: left;
    width: 80px;
    padding-left: 10px;
  }

  .siteLogo {
    color: $white;
    margin-top: -27px;
    font-size: 18px;
    padding-left: 5px;
  }

  .appWrapper p {
    font-size: 14px;
    margin: 0 auto;
    width: 90%;
    line-height: normal;
    font-family: sans-serif;
  }

  .appWrapper h3 {
    font-size: 24px;
  }

  .MuiButton-root {
    background: $button;
    color: $white;
    font-size: 12px;
    width: 150px;
    text-align: center;
    display: block;
    margin: 0 auto;
    margin-bottom: 0;
    overflow: hidden;
    position: relative;
    transform: translatez(0);
  }

  .menuHamIcon {
    margin-top: -10px;
    text-align: center;
  }

  /* Nav Bar */

  .navBar {
    margin-top: 10px;
  }

  .navLogo {
    width: 105px;
    height: 40px;
  }

  /* Page Content */

  .aboutAIContainer {
    background-image: linear-gradient(to right, #ffffff, #e0eff9);
    width: 100%;
    height: 100%;
    padding-bottom: 25px;
  }

  .aboutAIContainer .aboutTitle {
    color: $primary;
    margin: 100px auto;
    font-size:22px;
    position: absolute;
    padding-left: 20px;
    background: transparent;
    border-radius: 5px;
    text-align: left;
  }

  #gear1,
  #gear2 {
    display: none;
  }

  .approachAIContainer {
    background-image: linear-gradient(to bottom, #f1f9ff, #b7d6ea);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    padding-top: 15px;
    padding-bottom: 15px;
    word-wrap: break-word;
  }

  .approachAITitle {
    margin-top: -40px;
  }

  .approachAIContainer h3,
  h5 {
    color: $primary;
  }

  .approachAIContainer h5 {
    font-size: 20px;
    padding-bottom: 15px;
  }

  .approachAIContainer h3 {
    text-align: left;
    padding-left: 30px;
    font-size: 20px;
  }

  .approachAIContainer .rightSection {
    width: 95%;
    float: right;
    padding: 15px;
  }

  .approachAIContainer .leftSection {
    float: left;
    padding: 15px;
    margin-top: 100px;
  }

  .productQuadrantsContainer {
    padding-top: 10px;
    background: #f6fcff;
    margin: 0 auto;
    width: 95%;
    height: 100%;
    display: flex;
    font-size: 16px;
    word-wrap: break-word;
  }

  .productQuadrantsContainer h5 {
    font-size: 18px;
  }

  .productQuadrantsContainer img {
    text-align: center;
    width: 50%;
  }

  /* About Us Section */

  .aboutUsPageContainer {
    flex-grow: 1;
    width: 90%;
    height: 100%;
    margin: 0 auto;
  }

  .aboutUsPageContainer .pageTitle {
    color: $primary2;
    font-weight: bold;
    font-size: 22px;
  }

  .aboutUsPageContainer .aboutUsInfoSection {
    margin: 0 auto;
  }

  .aboutUsPageContainer .aboutUsInfoSection .title {
    font-size: 22px;
    background-image: linear-gradient(to left, #006BB6, #006BB6);
    color: #ffffff;
    padding: 15px;
  }

  .aboutUsPageContainer .aboutUsInfoSection .description {
    font-size: 18px;
    color: #333;
    padding-bottom: 50px;
    padding-left: 25px;
    padding-right: 25px;
    height: auto;
  }

  .aboutUsPageContainer .profileContainer {
    margin: 25px auto;
    text-align: center;
  }

  .profileContainer .profileInfo {
    margin: 50px auto;
    text-align: center;
  }

  .profileContainer .profileInfo .name {
    font-size: 20px;
    color: $primary2;
    font-weight: bold;
    
  }

  .profileContainer .profileInfo .title {
    font-size: 18px;
    color: $primary;
  }

  .profileContainer .profileInfo .email {
    font-size: 15px;
    color: #7a99b0;
  }

  /* Video Demo */

  .videoDemoCarouselContainer {
    height: auto;
    width: 100%;
    padding-top: 30px;
    margin-top: 50px;
  }

  .videoDemoCarouselContainer video {
    width: 85%;
    height: auto;
  }

  .videoCarousel {
    padding-top: 50px;
    padding-bottom: 20px;
    background: $primary;
  }

  .videoDemoCarouselContainer h3 {
    font-size: 40px;
    margin-top: 50px;
  }

  .videoDemoCarouselContainer video {
    border-radius: 7px;
  }

  .videoDemoCarouselContainer h5 {
    color: $white;
    margin-top: 50px;
    font-size: 20px;
  }

  /* FAQ Section */
  .faqContentContainer {
    flex-grow: 1;
    width: 100%;
    height: auto;
    margin: 25px auto;
    word-wrap: break-word;
  }

  .faqContentContainer .accordionContainer {
    background-image: linear-gradient(to bottom, #f3f8fc, #e1f1fc);
    padding: 25px;
    border-radius: 10px;
  }

  .faqContentContainer .category {
    font-size: 24px;
    color: #a4bfd0;
    text-align: left;
    padding: 20px;
    padding-left: 100px;
  }

  .faqContentContainer .titleLine {
    width: 90%;
    height: 2px;
    background-image: linear-gradient(to left, #f8fcff, #e1f1fc);
  }

  .faqContentContainer h5 {
    font-size: 20px;
  }

  .faqContentContainer p {
    margin: 0 auto;
    font-size: 18px;
    width: 100%;
    color: #333;
    text-align: center;
  }

   /* partnersContainer */ 
   .partnersContainer {
    margin: 10px auto;
    padding-bottom: 100px;

  }

  .partnersContainer h5 {
    color: $primary;
    text-align: left;
    font-size: 26px;
  }

  /* About Us Section */
  .aboutUsPageContainer {
    flex-grow: 1;
    width: 90%;
    height: 100%;
    margin: 0 auto;
  }

  .aboutUsPageContainer .pageTitle {
    color: $primary2;
    font-weight: bold;
    font-size: 22px;
  }

  .aboutUsPageContainer .aboutUsInfoSection {
    margin: 0 auto;
  }

  .aboutUsPageContainer .aboutUsInfoSection .title {
    font-size: 18px;
    background-image: linear-gradient(to left, #006BB6, #006BB6);
    color: #ffffff;
    padding: 15px;
  }

  .aboutUsPageContainer .aboutUsInfoSection .description {
    font-size: 18px;
    color: #333;
    padding-left: 25px;
    padding-right: 25px;
    height: 175px;
  }

  /* Demo Videos Section */
  .demoContainer .videoThumbnailContainer {
    height: 800px;
    background: $cardColor;
    border-radius: 10px;
    overflow: auto;
    text-align: left;
    margin: 0;
  }

  .demoContainer .videoThumbnailContainer .Link:hover {
    background: $link;
    animation: fadeInAnimation ease 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }

  .demoContainer .contentContainer {
    background-image: linear-gradient(to bottom, #f1f9ff, #f6fcff);
    height: 100%;
  }

  .demoContainer .videoThumbnailContainer .listMediaTitle:hover {
    color: $white;
  }

  .demoContainer h6 {
    font-size: 14px;
  }

  .demoContainer small {
    color: $h3;
    opacity: 0.7;
    font-size: 12px;
    text-align: left;
  }

  /* Contact Container */
  .contactPageContainer {
    height: 100%;
  }
  .contactUsContentContainer {
   height: 100%;
  }

  /* Video Carousel Home Page Section */

  .videoDemoCarouselContainer {
    height: auto;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-top: 30px;
  }

  .videoDemoCarouselContainer .sectionTitle {
    text-align: left;
  }

  .videoDemoCarouselContainer .sectionDescription {
    font-size: $desktopFontSize;
    margin: 0 auto;
    text-align: left;
  }

  .videoDemoCarouselContainer .sectionDescription .title {
    margin-left: 40px;
  }

  .videoDemoCarouselContainer .sectionDescription .text {
    text-align: left;
    font-size: $desktopFontSize;
    width: 90%;
    margin: 0 auto;
  }

  .videoDemoCarouselContainer .button {
    margin-top: 15px;
    border-radius: 50px;
    height: 60px;
    padding: 15px;
    background-color: transparent;
    color: $primary;
    border: 2px solid $primary;
    font-size: 14px;
  }

  .videoDemoCarouselContainer video {
    width: 85%;
    height: auto;
    box-shadow: 0 10px 10px 0 #03365b;
  }

  .videoCarousel {
    padding-top: 50px;
    padding-bottom: 20px;
    background: $secondary;
  }

  .videoDemoCarouselContainer h3 {
    font-size: 40px;
    margin-top: 50px;
  }

  .videoDemoCarouselContainer video {
    border-radius: 7px;
  }

  .videoDemoCarouselContainer h5 {
    color: $primary;
    font-size: 22px;
  }

  /* Offerings Page */
  .offeringsContentContainer {
    height: 100%;
    padding-bottom: 100px;
  }

  .offeringsContentContainer .container {
    margin: 0 auto;
    padding: 0 25px 0 25px;
  }

  .offeringsContentContainer .container .image {
    width: 200px;
    text-align: left;
  }

  .offeringsContentContainer .title {
    text-align: left;
    margin: 0 auto;
    width: 100%;
  }

  .offeringsContentContainer .desc {
    text-align: left;
    width: 100%;
    margin: 0 auto;
  }

  /* Layer Cake */

  .layerCakeContent {
    border-radius: 10px;
    width: 100%;
    height: 100%;
    margin: 0 auto;
  }
  .layerCakeContentSideA,
  .layerCakeContentSideB {
    margin: 0 auto;
    font-family: sans-serif;

    padding-bottom: 150px;
  }

  .layerCakeContentSideA {
    background-image: linear-gradient(to bottom, #ffffff, #d8eefc);
  }

  .layerCakeContentSideB {
    background-image: radial-gradient(#f2f2f2 1.5px, transparent 0.5px);
    background-size: 10px 10px;
    background-color: #ffffff;
    padding: 50px;

    margin-top: -110px;
  }

  .layerCakeLayerTitle {
    margin-top: 25px;
    padding-left: 50px;
    text-align: left;
    font-size: 15px;
    color: $white;
  }

  .layerCakeContentSideA .desc {
    width: 80%;
    margin: 0 auto;
    text-align: left;
    color: $white;
    font-size: 16px;
  }

  .layerCakeContentSideB .header {
    text-align: left;
    font-weight: 600;
    width: 90%;
    margin: 25px auto;
  }

  .layerLogo {
    width: 175px;
  }

  /* Component */

  .Card {
    text-align: center;
    background: transparent;
  }

  .Card .cardText {
    font-size: 16px;
    width: 75%;
    word-wrap: break-word;
    text-align: left;
    color: $body1;
    margin: 0 auto;
  }
  .Card h5 {
    color: $primary;
    font-size: 17px;
    width: 95%;
    height: 70px;
    margin: 0 auto;
    padding: 20px;
    border-radius: 10px 10px 0 0;
    color: #ffffff;
    word-wrap: break-word;
  }

  /* Profile Card Section */

  .ProfileCard {
    width: 100%;
    text-align: center;
  }

  .ProfileCard img {
    text-align: center;
    margin: 25px auto;
    height: 200px;
    border-radius: 10px;
    box-shadow: 5px 10px 8px 5px rgb(113, 113, 113);
    transform: rotate(2deg);
  }


 /* PartnersCard */ 
 .PartnersCard {

  text-align: left;
  height: auto;
}

.PartnersCard img{
 padding-top: 25px;
 width: 50%;
 margin: 0 auto;
 text-align: center;
}

  /* Badge */

  .badgeComponentContainer {
    float: left;
    margin: 0;
    padding: 20px;
  }
}
