@import "./variables.scss";

/* General */

body {
  background: $bodyBackgroundColor;
  font-size: $bodyFontSize;
  font-family: $bodyFontFamily;
}

ul {
  list-style: $ulListStyle;
}

.appWrapper {
  flex-grow: $appWrapperFlex;
  @media (max-width: $desktopResWidth) {
    font-size: $appWrapperFontSize;
  }
}

/* Header Bar */
.MuiAppBar-root {
  background: $appBarBckgrdColor;
}

/* Align Items */
.marginCenter {
  margin: $marginCenter;
  text-align: $marginCenterTxtAlign;
}

/* Page Title */
.pageTitleSection {
  color: $pageTitleColor;
  font-size: $pageTitleFontSize;
  text-align: $left;
  text-transform: $pageTitleUppercase;
  background-image: $pageTitleBckGrdImg;
  padding: $pageTitlePadding;
}

.pageSubTitleSection {
  color: $pageTitleColor;
  font-size: $pageTitleFontSize;
  text-align: $left;
  text-transform: $pageTitleUppercase;
  padding: $pageTitlePadding;
  text-align: $pageSubTitleAlign;
}

/* Fab */
.MuiFab-root {
  background: #28A745;
  color: #F8F8F8;
}

/* Menu */
.MuiTab-root {
  background: $tabBackgrdColor;
  width: $tabWidth;
  color: $tabColor;
  margin: $tabMargin;
  text-align: $tabTxtAlign;
}

/* List */

.MuiList-root {
  background: $listBckgrdColor;
}

.MuiListItem-root {
  color: $listItemColor;
}

/* Link */
.MuiLink-root {
  color: $linkColor;
}

/* Spacing */
.spacer {
  background: $spacerBckgrdColor;
  width: $spacerWidth;
  height: $spacerHeight;
}

.pageSpacer {
  background: $pageSpacerBckgrdColor;
  width: $pageSpacerWidth;
  height: $pageSpacerHeight;
}

/* Divider */
.footerShape {
  width: $footerShapeWidth;
  height: $footerShapeHeight;
  transform: $footerShapeSkew;
  background-image: $footerShapeLinGrad;
}

.dividerShape {
  margin: $dividerShapeMargin;
  width: $dividerShapeWidth;
  height: $dividerShapeHeight;
  background-image: $dividerShapeBckgrdImg;
}

.lineDivider {
  width: $lineDividerWidth;
  background-image: $lineDividerBckgrdImg;
  height: $lineDividerHeight;
}

/* Badge */
.MuiBadge-colorPrimary {
  background: $badgeBckgrdColor;
}

/* Accordion */
.MuiAccordionSummary-root {
  background: $accordionBckgrdColor;
}

.MuiAccordionDetails-root {
  width: $accordionDetailsWidth;
  margin: $accordionDetailsMargin;
  padding: $accordionDetailsPadding;
}

/* Button */
.MuiButton-root {
  width: $buttonWidth;
  height: $buttonHeight;
  margin: $buttonMargin;
}

/* Chip */
.MuiChip-root {
  margin-left: $chipMarginLeft;
  float: $chipFloat;
}
