@import "./variables.scss";

/* Layer Cake Animation */

#wrapD3Cube {
  width: 100%;
  height: 100%;
  margin: 75px auto;
}

#arrowAnim {
  margin-top: 200px;
  transform: rotate(90deg);
}

/* Cube */

#D3Cube {
  width: 112px;
  height: 112px;
  transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
  transform: rotateX(-22deg) rotateY(-38deg) rotateZ(0deg);
  -moz-transform: rotateX(-22deg) rotateY(-38deg) rotateZ(0deg);
  -webkit-transform: rotateX(-22deg) rotateY(-38deg) rotateZ(0deg);
  margin: 0 auto;
  // position: absolute;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
#D3Cube > div {
  position: absolute;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  width: 112px;
  height: 112px;
  float: left;
  overflow: hidden;
}
#D3Cube:hover {
  transform: rotateY(15deg) translateX(0px) translateY(0px) translateZ(50px);
}

#D3Cube2 {
  width: 112px;
  height: 112px;
  transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
  transform: rotateX(-22deg) rotateY(-38deg) rotateZ(0deg);
  -moz-transform: rotateX(-22deg) rotateY(-38deg) rotateZ(0deg);
  -webkit-transform: rotateX(-22deg) rotateY(-38deg) rotateZ(0deg);
  margin: 50px auto;
  // position: absolute;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
#D3Cube2 > div {
  position: absolute;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  width: 112px;
  height: 112px;
  float: left;
  overflow: hidden;
}
#D3Cube2:hover {
  transform: rotateY(15deg) translateX(0px) translateY(0px) translateZ(36px);
}

#D3Cube3 {
  width: 112px;
  height: 112px;
  transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
  transform: rotateX(-22deg) rotateY(-38deg) rotateZ(0deg);
  -moz-transform: rotateX(-22deg) rotateY(-38deg) rotateZ(0deg);
  -webkit-transform: rotateX(-22deg) rotateY(-38deg) rotateZ(0deg);
  margin: 0 auto;
  // position: absolute;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
#D3Cube3 > div {
  position: absolute;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  width: 112px;
  height: 112px;
  float: left;
  overflow: hidden;
}
#D3Cube3:hover {
  transform: rotateY(15deg) translateX(0px) translateY(0px) translateZ(36px);
}

#side1A {
  transform: rotatex(90deg) translateX(0px) translateY(0px) translateZ(56px);
  -moz-transform: rotatex(90deg) translateX(0px) translateY(0px)
    translateZ(56px);
  -webkit-transform: rotatex(90deg) translateX(0px) translateY(0px)
    translateZ(56px);
    background-image: linear-gradient(
      to bottom,
      $missionAccelShadeMedium,
      $missionAccelShadeLight
    );
}
#side2A {
  transform: rotateY(-90deg) translateX(0px) translateY(0px) translateZ(56px);
  -moz-transform: rotateY(-90deg) translateX(0px) translateY(0px)
    translateZ(56px);
  -webkit-transform: rotateY(-90deg) translateX(0px) translateY(0px)
    translateZ(56px);
  background-image: linear-gradient(to bottom, $missionAccelShadeMedium, $missionAccelBase);
}
#side3A {
  transform: translateX(0px) translateY(0px) translateZ(56px);
  -moz-transform: translateX(0px) translateY(0px) translateZ(56px);
  -webkit-transform: translateX(0px) translateY(0px) translateZ(56px);
  background-image: linear-gradient(
    to bottom,
    $missionAccelBase,
    $missionAccelShadeMedium
  );
}

#side4A {
  transform: rotateY(90deg) translateX(0px) translateY(0px) translateZ(56px);
  -moz-transform: rotateY(90deg) translateX(0px) translateY(0px)
    translateZ(56px);
  -webkit-transform: rotateY(90deg) translateX(0px) translateY(0px)
    translateZ(56px);
  background-image: url("../images/MissionAccel-Clr-Logo.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100px;
  background-color: $missionAccelShadeLight;
}

#side5A {
  transform: rotateY(180deg) translateX(0px) translateY(0px) translateZ(56px);
  -moz-transform: rotateY(180deg) translateX(0px) translateY(0px)
    translateZ(56px);
  -webkit-transform: rotateY(180deg) translateX(0px) translateY(0px)
    translateZ(56px);
  background-color: $missionAccelShadeMedium;
}
#side6A {
  transform: rotateX(-90deg) translateX(0px) translateY(0px) translateZ(56px);
  -moz-transform: rotateX(-90deg) translateX(0px) translateY(0px)
    translateZ(56px);
  -webkit-transform: rotateX(-90deg) translateX(0px) translateY(0px)
    translateZ(56px);
  background-image: url("../images/MissionAccel-Clr-Logo.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100px;
  background-color: $missionAccelShadeMedium;
  
}

#side1B {
  transform: rotatex(90deg) translateX(0px) translateY(0px) translateZ(56px);
  -moz-transform: rotatex(90deg) translateX(0px) translateY(0px)
    translateZ(56px);
  -webkit-transform: rotatex(90deg) translateX(0px) translateY(0px)
    translateZ(56px);
  background-image: linear-gradient(to bottom, $tenjinBase, $tenjinShadeMedium);
}
#side2B {
  transform: rotateY(-90deg) translateX(0px) translateY(0px) translateZ(56px);
  -moz-transform: rotateY(-90deg) translateX(0px) translateY(0px)
    translateZ(56px);
  -webkit-transform: rotateY(-90deg) translateX(0px) translateY(0px)
    translateZ(56px);
  background-image: linear-gradient(to bottom, $tenjinShadeDark, $tenjinBase);
}
#side3B {
  transform: translateX(0px) translateY(0px) translateZ(56px);
  -moz-transform: translateX(0px) translateY(0px) translateZ(56px);
  -webkit-transform: translateX(0px) translateY(0px) translateZ(56px);
  background-image: linear-gradient(to bottom, $tenjinBase, $tenjinShadeMedium);
}

#side4B {
  transform: rotateY(90deg) translateX(0px) translateY(0px) translateZ(56px);
  -moz-transform: rotateY(90deg) translateX(0px) translateY(0px)
    translateZ(56px);
  -webkit-transform: rotateY(90deg) translateX(0px) translateY(0px)
    translateZ(56px);
  // background-image: url("../images/Tenjin-Clr-Logo.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100px;
  background-color: $tenjinShadeLight;
}

#side5B {
  transform: rotateY(180deg) translateX(0px) translateY(0px) translateZ(56px);
  -moz-transform: rotateY(180deg) translateX(0px) translateY(0px)
    translateZ(56px);
  -webkit-transform: rotateY(180deg) translateX(0px) translateY(0px)
    translateZ(56px);
}
#side6B {
  transform: rotateX(-90deg) translateX(0px) translateY(0px) translateZ(56px);
  -moz-transform: rotateX(-90deg) translateX(0px) translateY(0px)
    translateZ(56px);
  -webkit-transform: rotateX(-90deg) translateX(0px) translateY(0px)
    translateZ(56px);
}

#side1C {
  transform: rotatex(90deg) translateX(0px) translateY(0px) translateZ(56px);
  -moz-transform: rotatex(90deg) translateX(0px) translateY(0px)
    translateZ(56px);
  -webkit-transform: rotatex(90deg) translateX(0px) translateY(0px)
    translateZ(56px);
 
  background-image: linear-gradient(
    to bottom,
    $koverseBase,
    $koverseShadeMedium
  );
}
#side2C {
  transform: rotateY(-90deg) translateX(0px) translateY(0px) translateZ(56px);
  -moz-transform: rotateY(-90deg) translateX(0px) translateY(0px)
    translateZ(56px);
  -webkit-transform: rotateY(-90deg) translateX(0px) translateY(0px)
    translateZ(56px);
  background-image: linear-gradient(
    to bottom,
    $koverseShadeDark,
    $koverseBase
  );



}
#side3C {
  transform: translateX(0px) translateY(0px) translateZ(56px);
  -moz-transform: translateX(0px) translateY(0px) translateZ(56px);
  -webkit-transform: translateX(0px) translateY(0px) translateZ(56px);
  background-image: linear-gradient(
    to bottom,
    $koverseBase,
    $koverseShadeMedium
  );
}

#side4C {
  transform: rotateY(90deg) translateX(0px) translateY(0px) translateZ(56px);
  -moz-transform: rotateY(90deg) translateX(0px) translateY(0px)
    translateZ(56px);
  -webkit-transform: rotateY(90deg) translateX(0px) translateY(0px)
    translateZ(56px);
  background-image: url("../images/Koverse-Clr-Logo.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100px;
  background-color: $koverseShadeLight;
}

#side5C {
  transform: rotateY(180deg) translateX(0px) translateY(0px) translateZ(56px);
  -moz-transform: rotateY(180deg) translateX(0px) translateY(0px)
    translateZ(56px);
  -webkit-transform: rotateY(180deg) translateX(0px) translateY(0px)
    translateZ(56px);
}
#side6C {
  transform: rotateX(-90deg) translateX(0px) translateY(0px) translateZ(56px);
  -moz-transform: rotateX(-90deg) translateX(0px) translateY(0px)
    translateZ(56px);
  -webkit-transform: rotateX(-90deg) translateX(0px) translateY(0px)
    translateZ(56px);
}

.wrap {
  margin-left: 150px;
  margin-top: 25px;
  perspective: 1200px;
  perspective-origin: -35% 100px;
  text-align: center;
}

.cube {
  position: relative;
  width: 150px;
  transform-style: preserve-3d;
}

.cube div {
  position: absolute;
  width: 150px;
  height: 150px;
}

.cube2 {
  position: relative;
  width: 150px;
  transform-style: preserve-3d;
}

.cube2 div {
  position: absolute;
  width: 150px;
  height: 150px;
}

.back {
  transform: translateZ(-55px) rotateY(180deg);
  background-color: #2a7ebe;
  opacity: 0.5;
}
.right {
  transform: rotateY(-270deg) translateX(100px);
  transform-origin: top right;
  background-color: #185482;
}
.left {
  transform: rotateY(270deg) translateX(-50px);
  transform-origin: center left;
  background-color: #2b75ad;
}
.top {
  transform: rotateX(-90deg) translateY(-100px);
  transform-origin: top center;
  background-color: #14588c;
}
.bottom {
  transform: rotateX(90deg) translateY(100px);
  transform-origin: bottom center;
  background-color: #166098;

  filter: drop-shadow(0.2rem 0.1rem 1rem #6f8393);
}
.front {
  transform: translateZ(100px);
  background-color: #59b0f2;
}

.front .content {
  margin-top: 45px;
  color: $black;
}

/* Animated Arrows */

.arrow {
  width: 25px;
  height: 25px;
  border: 15px solid;
  border-color: rgb(195, 195, 195) transparent transparent rgb(195, 195, 195);
  transform: rotate(-45deg);
}

.arrowSliding {
  position: absolute;
  -webkit-animation: slide 4s linear infinite;
  animation: slide 4s linear infinite;
}

.delay1 {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}
.delay2 {
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}
.delay3 {
  -webkit-animation-delay: 3s;
  animation-delay: 3s;
}

@-webkit-keyframes slide {
  0% {
    opacity: 0;
    transform: translateX(15vw);
  }
  20% {
    opacity: 1;
    transform: translateX(9vw);
  }
  80% {
    opacity: 1;
    transform: translateX(-9vw);
  }
  100% {
    opacity: 0;
    transform: translateX(-15vw);
  }
}
@keyframes slide {
  0% {
    opacity: 0;
    transform: translateX(15vw);
  }
  20% {
    opacity: 1;
    transform: translateX(9vw);
  }
  80% {
    opacity: 1;
    transform: translateX(-9vw);
  }
  100% {
    opacity: 0;
    transform: translateX(-15vw);
  }
}
